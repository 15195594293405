























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class LiveMeetsActions extends Vue {
  @Prop({ required: true }) live!: any;
  @Prop({ default: 20 }) sizeIcon!: number;
  @Prop({ default: false }) disableDelete!: Boolean;
  @Prop({ required: true }) canEdit!: Boolean;

  deleteLive() {
    this.$emit('deleteLive', this.live);
  }
}
