<template>
  <div class="live-list-table">
    <hs-table
      ref="liveListTable"
      selectable
      select-mode="single"
      responsive="md"
      :fields="fields"
      :items.sync="lives"
      :busy="loading"
    >
      <template slot="lesson.release_at" slot-scope="row">
        <div class="px-3 py-1">
          <div class="d-flex align-items-center">
            <div v-if="liveToEdit && liveToEdit.id === row.item.lesson.id">
              <hs-input
                class="d-inline"
                :placeholder="$t(`${prefixLang}.input-content-name.placeholder`)"
                :text="$t(`${prefixLang}.input-content-name.text`)"
                :value="liveToEdit.title"
                @click.stop=""
                @keyup.stop.enter="onUpdateLesson(row.item.lesson, { title: $event.target.value })"
              />
            </div>
            <div v-else class="d-flex align-items-center">
              <h5
                class="live-title text-dark mr-1 text-truncate"
                v-b-tooltip.hover.bottom="checkTitleSize(row.item.lesson.title)"
              >
                {{ row.item.lesson.title }}
              </h5>
              <MButton
                v-if="canEdit"
                size="sm"
                class="edit-live"
                icon="pencil"
                @click.stop="liveToEdit = row.item.lesson"
              />
            </div>
          </div>
          <div class="d-flex justify-content-start tlw-gap-10">
            <div class="d-flex align-items-center">
              <hs-icon variant="light" icon="calendar-alt" class="pr-1" />
              <span>{{ formatLiveDate(row.item.lesson.release_at) }}</span>
            </div>
            <div class="d-flex align-items-center">
              <hs-icon variant="light" icon="clock" class="pr-1" />
              <span class="live-end">{{ displayTimeInfo(row.item.lesson) }}</span>
              <hs-icon
                icon="info-circle"
                class="ml-2 informative-icon"
                :size="15"
                v-b-tooltip.hover.top="'Horário de Brasília'"
              />
            </div>
          </div>
        </div>
      </template>
      <template slot="available" slot-scope="row">
        <div class="d-flex align-items-center">
          <MSwitch :disabled="!canEdit" v-model="row.item.available" @change="changeStatus(row.item)" class="mr-2" />
          <span>{{
            row.item.available
              ? $t(`${prefixLang}.publish-switch.published`)
              : $t(`${prefixLang}.publish-switch.unpublished`)
          }}</span>
        </div>
      </template>
      <template slot="transmission" slot-scope="row">
        <div class="d-flex align-items-center">
          <div
            class="w-100 d-flex justify-content-center"
            v-b-tooltip.hover.bottom="
              checkLiveStatus(row.item, ['available', 'done']) ? false : $t(`${prefixLang}.tooltip-access-live`)
            "
          >
            <MButton
              class="w-100 access-button"
              :disabled="!(row.item.status === 'available')"
              :variant="checkLiveStatus(row.item, ['done']) ? 'secondary-disabled' : 'primary'"
              :label="
                checkLiveStatus(row.item, ['done'])
                  ? $t(`${prefixLang}.btn-live-ended`)
                  : $t(`${prefixLang}.btn-access-live`)
              "
              @click="accessTransmission(row.item.lesson)"
            />
          </div>
        </div>
      </template>
      <template slot="actions" slot-scope="row">
        <div class="d-flex justify-content-center">
          <LiveMeetsActions
            :can-edit="canEdit"
            :sizeIcon="25"
            :disableDelete="checkLiveStatus(row.item, ['available'])"
            :live="row.item"
            v-on="$listeners"
          />
        </div>
      </template>
      <template slot="status" slot-scope="row">
        <div :class="additionalHourInform(row.item)">
          <hs-icon variant="light" icon="info-circle" class="pr-2" />
          <span>{{ $t(`${prefixLang}.recording-status.additional-time`) }}</span>
        </div>
        <div :class="showRecordingStatus(row.item)">
          <hs-icon variant="light" icon="video" class="pr-2" />
          <span
            class="font-size-xs"
            :class="recordingStatusClass(row.item.lesson.media)"
            v-html="recordingStatusText(row.item.lesson.media)"
          ></span>
        </div>
      </template>
    </hs-table>
    <MButton
      v-if="canEdit"
      class="btn-student-view mt-4 d-none d-md-block"
      icon="plus-circle"
      :label="$t(`${prefixLang}.btn-new-live`)"
      variant="primary"
      @click="$router.push({ name: 'CreateLive', params: { module: module.course_modules } })"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import LiveMeetsActions from './LiveMeetsActions.vue';
import { courseService, lessonService } from '@/services';
import MButton from '@/shared/components/MButton.vue';
import ToastHelper from '@/shared/helpers/toast';
import MSwitch from '@/shared/components/MSwitch.vue';
import dayjs from 'dayjs';

export default {
  name: 'liveListTable',
  components: {
    LiveMeetsActions,
    MButton,
    MSwitch,
  },
  props: {
    lives: {
      required: true,
      type: Array,
    },
    module: {
      required: true,
      type: Object,
    },
    loading: {
      type: Boolean,
    },
    canEdit: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      liveToEdit: {},
      accessDisabled: true,
      prefixLang: 'sparkmembers.contents.views.lives.live-meet-list',
    };
  },
  computed: {
    ...mapState({
      course: state => state.course.selectedCourse,
      course_contents: state => state.course.courseContents,
      product: state => state.product.selectedProduct,
    }),
    fields() {
      return [
        {
          key: 'lesson.release_at',
          label: this.$t(`${this.prefixLang}.field-label.info`),
          sortable: true,
        },
        {
          key: 'available',
          label: this.$t(`${this.prefixLang}.field-label.status`),
          sortable: true,
        },
        {
          key: 'transmission',
          label: this.$t(`${this.prefixLang}.field-label.transmission`),
        },
        {
          key: 'actions',
          label: this.$t(`${this.prefixLang}.field-label.actions`),
        },
        {
          key: 'status',
          label: '',
          tdClass: 'p-0 recordingStatusCell',
        },
      ];
    },
  },
  methods: {
    additionalHourInform(item) {
      const lessonLockedOnPast =
        dayjs(item.lesson.lock_at)
          .utc()
          .diff(new Date()) < 0;
      return item.status === 'available' && lessonLockedOnPast ? 'additionalHourInform' : 'd-none';
    },
    displayTimeInfo(lesson) {
      return lesson.lock_at
        ? `${this.getHours(lesson.release_at)} - ${this.getHours(lesson.lock_at)}`
        : this.$t(`${this.prefixLang}.display-time-info`, { release: this.getHours(lesson.release_at) });
    },
    showRecordingStatus(item) {
      return item.status === 'done' && item.lesson.media?.type === 'Video' ? 'recordingStatus' : 'd-none';
    },
    recordingStatusClass(media) {
      if (media?.status === 'ready') {
        return '_available';
      } else if (media?.status === 'processing') {
        return '_processing';
      } else {
        return '';
      }
    },
    recordingStatusText(media) {
      if (media?.status === 'ready') {
        return this.$t(`${this.prefixLang}.recording-status.ready`);
      } else if (media?.status === 'processing') {
        return this.$t(`${this.prefixLang}.recording-status.processing`);
      } else {
        return '';
      }
    },
    checkLiveStatus(live, status) {
      return status.includes(live.status);
    },
    checkTitleSize(title) {
      return title.length > 23 ? title : false;
    },
    async onUpdateLesson(live, newProp = null) {
      if (newProp) {
        try {
          let [key, value] = Object.entries(newProp)[0];
          live[key] = value;
          await lessonService.update(live);
          ToastHelper.successMessage(this.$t(`${this.prefixLang}.toast.change-title.success`));
        } catch (e) {
          ToastHelper.dangerMessage(this.$t(`${this.prefixLang}.toast.change-title.error`));
        } finally {
          this.liveToEdit = {};
        }
      }
    },
    getHours(item) {
      return dayjs(item).format('HH:mm');
    },
    formatLiveDate(liveDate) {
      return liveDate ? dayjs(liveDate).format('L') : '';
    },
    async changeStatus(item) {
      const publish_action = item.available ? 'publish' : 'unpublish';
      try {
        await courseService.updateCourseContent({ ...item });

        ToastHelper.successMessage(this.$t(`${this.prefixLang}.toast.${publish_action}-live.success`));
      } catch (e) {
        ToastHelper.dangerMessage(this.$t(`${this.prefixLang}.toast.${publish_action}-live.error`));
      }
    },
    accessTransmission(live) {
      let route = this.$router.resolve({
        name: 'LiveMeetRoom',
        params: { liveMeetId: live.id },
        query: { id: this.product.id, content: this.course.id },
      });
      window.open(route.href, '_blank');
    },
    deleteLive(live) {
      this.$emit('deleteLive', live);
    },
  },
};
</script>

<style lang="scss">
.live-list-table {
  table {
    thead tr,
    tbody tr {
      display: grid;
      grid-template-columns: 2fr 0.5fr 1.5fr 0.2fr;
      align-items: center;
      margin-bottom: 16px;
    }
    thead tr > th:nth-child(3) {
      justify-self: center;
    }
    tbody tr {
      .recordingStatusCell {
        grid-column: span 4;
        .additionalHourInform {
          span,
          i {
            color: $grey-32;
          }
        }
        .additionalHourInform,
        .recordingStatus {
          display: flex;
          align-items: center;
          border-top: 2px solid #ead3fe;
          padding: 0.5rem 1.75rem;
          span {
            &._available b {
              color: $green-dark;
            }
            &._processing b {
              color: #3575d4;
            }
          }
        }
      }
    }
  }
  .edit-live {
    color: $purple-dark;
  }
  .live-title {
    font-weight: 600;
    max-width: 200px;
  }
  .table.b-table {
    tbody tr td:nth-child(1) {
      padding-right: 0;
    }

    th {
      color: $grey-32;
    }

    th[aria-sort] {
      .sorting {
        &::after {
          content: '\f15d';
        }
      }
    }

    th[aria-sort='descending'] {
      .sorting {
        color: $cyan;

        &::after {
          content: '\f882';
        }
      }
    }

    th[aria-sort='ascending'] {
      .sorting {
        color: $cyan;

        &::after {
          content: '\f15d';
        }
      }
    }

    th[aria-sort]:nth-child(2) {
      .sorting {
        &::after {
          content: '\f160';
        }
      }
    }

    th[aria-sort='descending']:nth-child(2) {
      .sorting {
        color: $cyan;

        &::after {
          content: '\f885';
        }
      }
    }

    th[aria-sort='ascending']:nth-child(2) {
      .sorting {
        color: $cyan;

        &::after {
          content: '\f160';
        }
      }
    }
  }
  .access-button {
    max-width: 250px;
    min-width: 200px;
  }
  .live-end {
    max-width: 170px;
  }
  .informative-icon {
    color: #3575d4;
  }
}
</style>
